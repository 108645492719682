import React from 'react';
import { NavLink } from 'react-router-dom';

const Portfolio = () => {
    return (
        <div>
            <h1>Bienvenue, Je suis Mathieu Turko, UX/UI Designer</h1>
            <section className='portfolio'>
                <NavLink to="/projet_site_cogelog" exact className="hover" activeClassName="nav-active">
                    <article className='site_cogelog'></article> 
                </NavLink>  
                <NavLink to="/projet_site_innovance" exact className="hover" activeClassName="nav-active">
                    <article className='site_innovance'></article> 
                </NavLink>
                <NavLink to="/projet_skillsmarket" exact className="hover" activeClassName="nav-active">
                    <article className='skills'></article> 
                </NavLink>
                <NavLink to="/projet_app_patients" exact className="hover" activeClassName="nav-active">
                    <article className='app_patients'></article> 
                </NavLink>
                <NavLink to="/projet_tagasuga" exact className="hover" activeClassName="nav-active">
                    <article className='tagasuga'></article> 
                </NavLink>
            </section>
        </div>
    );
};

export default Portfolio;