import React from 'react';
import BurgerMenu from '../composants/BurgerMenu';
import Nav from '../composants/Nav';
import App_patients_details from '../composants/App_patients_details';

const App_patients = () => {
    return (
        <div>
            <Nav />
            <BurgerMenu />
            <App_patients_details />
        </div>
    );
};

export default App_patients;