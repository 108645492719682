import { NavLink } from 'react-router-dom';
import Pdf from './../assets/img/CV_mathieu_turko.pdf';

const Nav = () => {
    return (
        <nav>
            <div>
                <div className='logo'>
                    <NavLink to="/" exact className="hover" activeClassName="nav-active">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.34 44.04" className="logo"><defs></defs><title>MT</title><g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1"><path style={{fill: "#000"}} d="M24.91,27.37,8.43,0H0V44H9.56V18.19l12.9,21.2h4.6L33.86,28V12.29Z"/><path style={{fill: "#000"}} d="M40.05,8.31H26V0H64.34V8.31H50.25V44H40.05Z"/></g></g></svg>
                    </NavLink>
                </div>
                <div className='nom'>
                    <p>Mathieu Turko</p>
                </div>
                <div className='pages'>
                    <ul>
                        <NavLink to="/" exact className="hover" activeClassName="nav-active">
                            <li>Portfolio</li>
                        </NavLink>
                        <li><a id="cv_menu" href = {Pdf} target="_blank">CV</a></li>
                        <NavLink to="/contact" exact className="hover" activeClassName="nav-active">
                            <li>Contact</li>
                        </NavLink>   
                    </ul>  
                </div>
            </div>           
        </nav>
    );
}; 

export default Nav;