import React from 'react';
import { NavLink } from 'react-router-dom';
import banner from './../assets/img/banner1_site_cogelog.jpg';
import page_accueil from './../assets/img/cogelog/accueil.jpg';
import charte from './../assets/img/cogelog/charte_cogelog.svg';
import page_contact from './../assets/img/cogelog/contact.jpg';
import page_societe from './../assets/img/cogelog/societe.jpg';
import page_detail_recrutement from './../assets/img/cogelog/detail_recrutement.jpg';
import page_missions from './../assets/img/cogelog/missions.jpg';
import page_recrutement from './../assets/img/cogelog/recrutement.jpg';


const Skillsmarket_details = () => {
    return (
        <section className='cogelog_details'>
            <img src={banner} />
            <div className='description_projet'>
                <div>
                    <h2>Le projet :</h2>
                    <p>Designer et développer le site internet vitrine de la société parisienne Cogelog.</p>
                </div>
                <div>
                    <h2>Status :</h2>
                    <p>En cours...</p>
                </div>
                <div>
                    <h2>Outils :</h2>
                    <p>Figma, Photoshop, Illustrator</p>
                </div>
                <div>
                    <h2>Méthodes :</h2>
                    <p>Prototyping, UI Design</p>
                </div>
                <div>
                    <h2>Logo :</h2>
                    <p>Design de logo et création d'une charte graphique pour le site internet.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={charte} className='ombre' />
                </div>
                <div>
                    <h2>Page d'accueil :</h2>
                    <p>Design de la page d'accueil du site Cogelog.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_accueil} className='ombre' />
                </div>
                <div>
                    <h2>Page contact :</h2>
                    <p>Design de la page contact du site Cogelog.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_contact} className='ombre' />
                </div>
                <div>
                    <h2>Page société :</h2>
                    <p>Design de la page présentant la société.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_societe} className='ombre' />
                </div>
                <div>
                    <h2>Recrutement :</h2>
                    <p>Design de la page présentant les offres d'emploi disponibles.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_recrutement} className='ombre' />
                </div>
                <div>
                    <h2>Page missions :</h2>
                    <p>Design de la page présentant les différentes missions des consultants de Cogelog.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_missions} className='ombre' />
                </div>
                <div>
                    <h2>Détail offre :</h2>
                    <p>Design d'une page présentant une offre d'emploi type.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_detail_recrutement} className='ombre' />
                </div>
            </div>
            <NavLink to="/" exact className="hover" activeClassName="nav-active">
                <button>Retour à l'accueil</button>
            </NavLink>
        </section>
    );
};

export default Skillsmarket_details;