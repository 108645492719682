import React, { useState } from 'react';
import Nav from '../composants/Nav';
import BurgerMenu from '../composants/BurgerMenu';
// import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
  


    const isEmail = () => {
        let mail = document.querySelector('.form-message');
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.match(regex)){
            mail.style.display = 'none';
            return true;
        } else{
            mail.style.animation= "dongle 1s";
            setTimeout(() => {
                mail.style.animation = 'none';
            }, 1000)
            return false;
        }
    }

    const failMessage = () => {
        let formMess = document.querySelector('.form-message');
        formMess.innerHTML = 'Merci de remplir correctement les champs requis *';
        formMess.style.opacity = '1';
        formMess.style.background = "#B2312F";
    }

    const succesMessage = () => {
        let formMess = document.querySelector('.form-message');
        formMess.innerHTML = 'Votre message a bien été envoyé ! Merci à vous !';
        formMess.style.opacity = '1';
        formMess.style.background = "#64B27C";
        formMess.style.color = "#101A24";
    }


    const handleSubmit = (e) => {
      e.preventDefault();
      
      if (name && isEmail() && message){
        
        sendFeedback("template_bhqitok", {
            name,
            company,
            phone,
            email,
            message,
        });
      }else{
          failMessage();
      }
    };
  
    const sendFeedback = (templateId, variables) => {
  
      window.emailjs
        .send("service_d77u", templateId, variables)
        .then((res) => {
          setName("");
          setCompany("");
          setPhone("");
          setEmail("");
          setMessage("");
        })
        .catch(
          (err) =>
            document.querySelector('.form-message').innerHTML =
              "Une erreur s'est produite, veuillez réessayer.")
    };

    // const onChange = () => {
    // };
  
    return (
        <div>
            <Nav />
            <BurgerMenu />
            <section className='contact_page'>
                <div className="contact_title">
                    <h1>Contact</h1>
                </div>
                <p>Si vous avez besoin d'un designer UX pour une mission et que mon profil vous intéresse, merci d'écrire directement à <a href="mailto:contact@cogelog.fr">contact@cogelog.fr</a></p>
                {/* <div className="container-formulaire">
                    <form className="contact-form">
                        <div className="form-content">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="nom *"
                                value={name}
                                autoComplete="off"
                            />
                            <input
                                type="text"
                                id="company"
                                name="company"
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder="société"
                                value={company}
                            />
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="téléphone"
                                value={phone}
                            /> 
                            <input
                                type="mail"
                                id="email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="email *"
                                value={email}
                                autoComplete="off"
                            />
                            <textarea
                                id="message"
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="message *"
                                value={message}
                            />
                        </div>
                        <input
                        className="button"
                        type="button"
                        value="Envoyer"
                        onClick={handleSubmit}
                        />
                        <div className="form-message"></div>
                    </form>
                </div> */}
            </section>
        </div>
    );
  };

export default Contact;