import React from 'react';
import Nav from '../composants/Nav';
import BurgerMenu from '../composants/BurgerMenu';
import Portfolio from '../composants/Portfolio';

const Accueil = () => {
    return (
        <div className="home">
            <Nav />
            <BurgerMenu />
            <Portfolio />
        </div>
    ); 
};

export default Accueil;