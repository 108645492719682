import React from 'react';
import { NavLink } from 'react-router-dom';
import banner from './../assets/img/banner1_tagasuga.jpg';
import persona1 from './../assets/img/tagasuga/persona1.jpg';
import persona2 from './../assets/img/tagasuga/persona2.jpg';
import persona3 from './../assets/img/tagasuga/persona3.jpg';
import parcours1 from './../assets/img/tagasuga/parcours.jpg';
import parcours_bis from './../assets/img/tagasuga/parcours_bis.jpg';

const Tagasuga_details = () => {
    return (
        <section className='tagasuga_details'>
            <img src={banner} />
            <div className='description_projet'>
                <div>
                    <h2>Le projet :</h2>
                    <p>Création de personae, de parcours utilisateurs pour le futur site internet de la marque Tagasuga vendant du CBD ainsi que divers produits à base de CBD.</p>
                </div>
                <div>
                    <h2>Outils :</h2>
                    <p>Figma</p>
                </div>
                <div>
                    <h2>Méthodes :</h2>
                    <p>Personae, Parcours utilisateurs</p>
                </div>
                <div>
                    <h2>Recherche utilisateur :</h2>
                    <p>
                        N'ayant pas pu prendre contact avec les utilisateurs de Tagasuga, nous nous sommes basés sur des études officielles présentes sur internet à propos des consommateurs de CBD. 
                    </p>
                </div>
                <div>
                    <h2>Personae :</h2>
                    <p>Nous avons selectionné trois utilisateurs types pour en créer des personae.</p>
                </div>
                <div>
                    <img src={persona1} />
                </div>
                <div>
                    <img src={persona2} />
                </div>
                <div>
                    <img src={persona3} />
                </div>
                <div>
                    <h2>Parcours utilisateur :</h2>
                    <p>De ces personae découlent les différents parcours utilisateurs. On travaille à destination d'utilisateurs spécifiques, qui ont des buts spécifiques et qui évoluent dans des contextes bien précis.</p>
                </div>  
                <div>
                    <a href = {parcours1} target="_blank"><img src={parcours1} /></a>
                </div>
                <div>
                    <a href = {parcours_bis} target="_blank"><img src={parcours_bis} /></a>
                </div>
            </div>
            <NavLink to="/" exact className="hover" activeClassName="nav-active">
                <button>Retour à l'accueil</button>
            </NavLink>
        </section>
    );
};

export default Tagasuga_details;