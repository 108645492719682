import React from 'react';
import { NavLink } from 'react-router-dom';
import banner from './../assets/img/banner1_app_patients.jpg';
import wireframes from './../assets/img/wireframes.png';
import maquettes from './../assets/img/maquettes_app_patients.png'
import maquette_li_patients from './../assets/img/li_patients.jpg'

const App_patients_details = () => {
    return (
        <section className='app_patients_details'>
            <img src={banner} />
            <div className='description_projet'>
                <div>
                    <h2>Le projet :</h2>
                    <p>Nous devions designer une application destinée à des enfants/ jeunes adultes atteints de différentes pathologies afin de les aider à mieux comprendre ce qui leur arrive. </p>
                </div>
                <div> 
                    <h2>Durée :</h2>
                    <p>4 mois</p>
                </div>
                <div>
                    <h2>Outils :</h2>
                    <p>Adobe XD, Illustrator.</p>
                </div>
                <div>
                    <h2>Méthodes :</h2>
                    <p>Recherche Utilisateur, Wireframes, Prototyping, UI Design.</p>
                </div>
                <div>
                    <h2>Contexte :</h2>
                    <p>14eight est une agence digitale Mulhousienne spécialisée dans le domaine de la santé (Aujourd’hui liquidée en raison de la crise sanitaire).</p>
                </div>
                <div>
                    <h2>Client :</h2>
                    <p>Une association accompagnant des enfants vivant avec une maladie chronique.</p>
                </div>
                <div>
                    <h2>Recherche utilisateur :</h2>
                    <p>Nous avions à disposition, des données/informations de l’association cliente au sujet des différentes pathologies.</p>
                </div>
                <div>
                    <h2>Wireframes :</h2>
                    <p>Nous avons commencé par faire des wireframes, des maquettes en noir et blanc. Le but étant de se concentrer dans un premier temps sur le fond plutôt que la forme.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={wireframes} />
                </div>
                <div>
                    <h2>Détails</h2>
                    <p>Nous avions mis en place un questionnaire gamifié, destiné aux enfants, dans le but de voir dans un premier temps ce qu’eux comprenaient de leur propre pathologie. Suivant les résultats au quizz, un professionnel de santé avait la possibilité d’organiser une entrevue avec le patient pour confirmer ou infirmer la réponse du patient.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={maquettes} />
                </div>
                <div className='vertical_boxes'>
                    <img src={maquette_li_patients} />
                </div>
            </div>
            <NavLink to="/" exact className="hover" activeClassName="nav-active">
                <button>Retour à l'accueil</button>
            </NavLink>
        </section>
    );
};

export default App_patients_details;