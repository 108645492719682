import React from 'react';
import Nav from '../composants/Nav';
import BurgerMenu from '../composants/BurgerMenu';
import Tagasuga_details from '../composants/Tagasuga_details';

const Tagasuga = () => {
    return (
        <div>
            <Nav />
            <BurgerMenu />
            <Tagasuga_details />
        </div>
    );
};

export default Tagasuga;