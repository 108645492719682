import React from 'react';
import Nav from '../composants/Nav';
import BurgerMenu from '../composants/BurgerMenu';
import Site_innovance_details from '../composants/Site_innovance_details';

const Site_innovance = () => {
    return (
        <div>
            <Nav />
            <BurgerMenu />
            <Site_innovance_details />
        </div>
    );
};

export default Site_innovance;