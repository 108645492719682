import React from 'react';
import { NavLink } from 'react-router-dom';
import banner from './../assets/img/banner1_skills.jpg';
import skills_ui from './../assets/img/ui_skills.jpg'
import skills_responsive from './../assets/img/moqup_skills.jpg'
import landing_page1 from './../assets/img/skills/lp1.svg';
import landing_page2 from './../assets/img/skills/lp2.svg';

const Skillsmarket_details = () => {
    return (
        <section className='skills_details'>
            <img src={banner} />
            <div className='description_projet'>
                <div>
                    <h2>Le projet :</h2>
                    <p>Skillsmarket est une start-up qui a pour vocation de créer un tout nouveau réseau social professionnel basé sur l'entraide. J’ai intégré le projet en tant que UX/UI Designer</p>
                </div>
                <div>
                    <h2>Durée :</h2>
                    <p>6 mois</p>
                </div>
                <div>
                    <h2>Outils :</h2>
                    <p>Figma</p>
                </div>
                <div>
                    <h2>Méthodes :</h2>
                    <p>Recherche utilisateur, Personae, Interviews, Prototyping, Tests, UI Design</p>
                </div>
                <div>
                    <h2>Contexte :</h2>
                    <p>Skillsmarket est un moteur de réponses et d’échanges professionnels. À la manière d’un réseau social de l’emploi, les visiteurs sont en capacité de créer des publications sous forme de questions. C’est ici que vient la particularité de Skillsmarket : en publiant la question, l’utilisateur crée une room automatiquement. Dans cette room, des professionnels pourront répondre rapidement et gratuitement à la question de l’internaute. D’un côté nous avons un internaute avec une réponse de qualité, de l’autre, un professionnel avec un potentiel client.</p>
                </div>
                <div>
                    <h2>Problème :</h2>
                    <p>Le site internet n’était pas assez clair, pas assez ergonomique, mais possède un grand potentiel. Les visiteurs ne comprenaient pas à quoi servait le site. Les visiteurs se perdaient rapidement dans une interface loin d’être ergonomique et intuitive. Il fallait comprendre en deux secondes à quoi servait Skillsmarket. La grande question : « Comment rendre le site plus compréhensibles ? »</p>
                </div>
                <div>
                    <h2>Recherche utilisateur :</h2>
                    <p>
                        Nous nous sommes intéressés aux utilisateurs de Skillsmarket.
                        <br />Coeur de cible : les skillers, personnes / professionnels, de tout âge, ayant des compétences dans un domaine, quel qu’il soit, souhaitant donner leur aide
                        <br />Cible principale : toute personne ayant un problème à résoudre / se posant une question et souhaitant s’adresser à de vraies personnes.
                        Nous avons réalisé des interviews utilisateur filmées sur la plateforme existante afin de comprendre quels étaient réellement les points de friction, les incompréhensions, etc.
                    </p>
                </div>
                <div>
                    <h2>Idéation :</h2>
                    <p>C’est avec les retours utilisateurs, que nous avons compris que nous devions nous rapprocher de ce qu’est un réseau social et nous avons donc fait plusieurs essais pour arriver au résultat final.</p>
                </div>
                <div className='vertical_boxes'>
                    <h2>UI Design :</h2>
                    <img src={skills_ui} />
                </div>
                <div className='vertical_boxes'>
                    <h2>Responsive : </h2>
                    <img src={skills_responsive} />
                </div>
                <div>
                    <h2>Landing pages :</h2>
                    <p>Nous avons créé deux landing pages pour expliquer le fonctionnement de Skillsmarket. L'une pour amener des utilisateurs avec des questions à poser.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={landing_page1} className='ombre' />
                </div>
                <div><p>L'autre pour ramener des personnes avec des compétences en tout genre, cherchant de potentiels prospects.</p></div>
                <div className='vertical_boxes'>
                    <img src={landing_page2} className='ombre' />
                </div>
            </div>
            <NavLink to="/" exact className="hover" activeClassName="nav-active">
                <button>Retour à l'accueil</button>
            </NavLink>
        </section>
    );
};

export default Skillsmarket_details;