import React from 'react';
import Nav from '../composants/Nav';
import BurgerMenu from '../composants/BurgerMenu';

const Mentions_legales = () => {
    return (
        <div>
            <Nav />
            <BurgerMenu />  
            <div className="mentions_legales">
                <h1>Mentions légales</h1>
                <p>En accédant à ce site, vous acceptez, sans limitation ni réserve, les dispositions légales en vigueur et les conditions détaillées ci-dessous.</p>
                <p>Le site www.mathieuturko.com est la propriété de :</p>
                <ul>
                    <li>Mathieu Turko</li>
                    <li>67170 Brumath</li>
                    <li>FRANCE</li>
                    <li>E-mail : formulaire de contact</li>
                </ul>
                <h2>1 – Informations figurant sur le site</h2>
                <p>Le propriétaire du site fournit des informations à des fins purement informatives. Il s’efforce de contrôler leur exactitude et de les maintenir à jour. Mais aucune garantie n’est apportée concernant l’exactitude, la précision, la mise à jour ou l’exhaustivité de ces informations.</p>
                <p>Par conséquent et à l’exception d’une faute lourde et intentionnelle, le propriétaire du site décline toute responsabilité pour tout dommage résultant notamment d’une imprécision ou inexactitude des informations disponibles sur ce site, ou pour toute atteinte résultant d’une intrusion frauduleuse d’un tiers sur ce site, ou encore pour tout dommage ou virus qui pourrait endommager ou rendre inutilisable votre équipement informatique suite à la visite de ce site. Le propriétaire du site ne pourra être tenu responsable en cas d’indisponibilité du site pour quelque cause que ce soit.</p>
                <h2>2 – Propriété intellectuelle</h2>
                <p>L’accès à ce site vous confère un droit d’usage privé et non exclusif de ce site.</p>
                <p>L’ensemble des éléments édités sur ce site, incluant notamment les textes, images, logos, animations… constituent des œuvres de l’esprit au sens du Code de la Propriété Intellectuelle. Par conséquent, toute utilisation sous forme de représentation, modification, reproduction… intégrale ou partielle est illicite, sauf autorisation expresse du directeur de la publication.</p>
                <h2>4 – Formulaire de contact</h2>
                <p>Le formulaire de contact disponible sur ce site a pour but de faciliter le dialogue entre le visiteur et le webmaster. Ce dernier s’engage à apporter une réponse dans les plus brefs délais pour toute demande.</p>
            </div>
        </div>
    );
};

export default Mentions_legales;