import Normalize from 'react-normalize';
import { Redirect, Route, Switch } from "react-router-dom";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import ScrollToTop from './composants/ScrollToTop';
import Mentions_legales from './pages/Mentions_legales';
import Skillsmarket from './pages/Skillsmarket';
import App_patients from './pages/App_patients';
import Site_cogelog from './pages/Site_cogelog';
import Site_innovance from './pages/Site_innovance';
import Tagasuga from './pages/Tagasuga';

const App = () => {
  return (  
    <div>
      <Normalize />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Portfolio}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route exact path="/mentions_legales" component={Mentions_legales}></Route>
        <Route exact path="/projet_skillsmarket" component={Skillsmarket}></Route>
        <Route exact path="/projet_app_patients" component={App_patients}></Route>
        <Route exact path="/projet_site_cogelog" component={Site_cogelog}></Route>
        <Route exact path="/projet_site_innovance" component={Site_innovance}></Route>
        <Route exact path="/projet_tagasuga" component={Tagasuga}></Route>
        <Redirect to="/" />
      </Switch>
      
    </div>
  );
}

export default App;
