import React from 'react';
import { NavLink } from 'react-router-dom';
import banner from './../assets/img/banner1_innovance.jpg';
import charte from './../assets/img/innovance/charte_innovance.svg';
import accueil from './../assets/img/innovance/innovance_accueil.png';
import evolution_logo from './../assets/img/innovance/evolution_logo_innovance.png';
import page_societe from './../assets/img/innovance/societe.jpg';
import ancien_site from './../assets/img/innovance/capture_ancien_site.png';
import recrutement from './../assets/img/innovance/recrutement.png';
import contact from './../assets/img/innovance/contact.png';

const App_patients_details = () => {
    return (
        <section className='site_innovance_details'>
            <img src={banner} />
            <div className='description_projet'>
                <div>
                    <h2>Le projet :</h2>
                    <p>Refondre le site internet vitrine de la société parisienne Innovance ainsi que mettre à jour son logo.</p>
                </div>
                <div> 
                    <h2>Statut :</h2>
                    <p>En cours...</p>
                </div>
                <div>
                    <h2>Outils :</h2>
                    <p>Figma, Photoshop, Illustrator.</p>
                </div>
                <div>
                    <h2>Méthodes :</h2>
                    <p>Prototyping, UI Design</p>
                </div>
                <div>
                    <h2>Logo :</h2>
                    <p>Mise à jour du logo d'Innovance. Suppression des angles trop abruptes au profit de formes plus rondes. J'ai essayé de garder l'essence du logo, le 'i', tout en lui donnant plus d'importance. J'ai joué sur le négatif dans le rond bleu. J'ai choisi de garder la même police de caractère qui était convaincante, toute en augmentant légèrement la graisse de cette dernière pour une meilleure harmonie avec le logo.</p>
                </div>
                <div>
                    <img src={evolution_logo} />
                </div>
                <div>
                    <h2>Charte :</h2>
                    <p>Mise à jour de la charte graphique du site internet Innovance.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={charte} className='ombre' />
                </div>
                <div>
                    <h2>Ancien site :</h2>
                    <p>Voici une capture d'écran du site à mettre à jour.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={ancien_site} className='ombre' />
                </div>
                <div>
                    <h2>Accueil :</h2>
                    <p>Maquette de la nouvelle page d'accueil du site Innovance. Nous avons décidé de passer le site sur plusieurs page et d'abandonner le one-page.</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={accueil} className='ombre' />
                </div>
                <div>
                    <h2>Société :</h2>
                    <p>Maquette de la page société du site Innovance</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={page_societe} className='ombre' />
                </div>
                <div>
                    <h2>Recrutement :</h2>
                    <p>Maquette de la page recrutement du site Innovance</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={recrutement} className='ombre' />
                </div>
                <div>
                    <h2>Contact :</h2>
                    <p>Maquette de la page Contact du site Innovance</p>
                </div>
                <div className='vertical_boxes'>
                    <img src={contact} className='ombre' />
                </div>
            </div>
            <NavLink to="/" exact className="hover" activeClassName="nav-active">
                <button>Retour à l'accueil</button>
            </NavLink>
        </section>
    );
};

export default App_patients_details;